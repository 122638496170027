import { BaseApiError } from '@lumapps/base-api';
import { UseQueryOptions, useQuery } from '@lumapps/base-api/react-query';

import { microAppsQueryKeys, runMicroApp } from '../api';
import { RunMicroAppAPI, MicroAppProps } from '../types';

/**
 * Hook to get a micro app's data from its extension id.
 * @returns A react-query query Object.
 */
export const useRunMicroApp = (
    microAppId?: MicroAppProps['id'],
    runInstanceId?: MicroAppProps['runInstanceId'],
    inputVariables: Record<string, string> = {},
    options: UseQueryOptions<
        RunMicroAppAPI | undefined,
        BaseApiError,
        RunMicroAppAPI,
        ReturnType<typeof microAppsQueryKeys.runMicroApp>
    > = {},
) => {
    return useQuery<
        RunMicroAppAPI | undefined,
        BaseApiError,
        RunMicroAppAPI,
        ReturnType<typeof microAppsQueryKeys.runMicroApp>
    >({
        ...options,
        queryKey: microAppsQueryKeys.runMicroApp(microAppId, runInstanceId, inputVariables),
        queryFn: ({ signal }) => (microAppId ? runMicroApp(microAppId, signal, inputVariables) : undefined),
        enabled: options.enabled && Boolean(microAppId),
    });
};
